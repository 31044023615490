import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, onSnapshot, orderBy,query, setDoc, where } from "firebase/firestore"

import store from '../store/index'
import * as spotifyService from './spotifyService'

const addPlaylistSub = (db, id) => {
    // create unsubscribe reference
    const unsub = onSnapshot(
        doc(db, 'playlist', id),
        { includeMetadataChanges: false },
        intervalPlaylistWrap)

        const unsub1 = onSnapshot(
            query(collection(db, `playlist/${id}/songs`)),
            { includeMetadataChanges: false },
            intervalSongsWrap)

    // commit to store
    store.commit({
        type: 'addPlaylistSubscription',
        value: unsub
    })
    store.commit({
        type: 'addPlaylistSongsSubscription',
        value: unsub1
    })
}

const addPlaylistDocSub = (db, id) => {
    const unsub = onSnapshot(
        query(collection(db, `playlist/${id}/songs`)),
        { includeMetadataChanges: false },
        handleNewPlaylistDocSongs)
    store.commit({
        type: 'setPlaylistDocSub',
        value: unsub
    })
}

const addQueueSub = (db, id) => {
    const unsub = onSnapshot(
        doc(db, `queue/${id}`),
        { includeMetadataChanges: false },
        handleQueueData)

    const unsub1 = onSnapshot(
        query(collection(db, `queue/${id}/played`)),
        { includeMetadataChanges: false },
        handleQueuePlayed)

    const unsub2 = onSnapshot(
        query(collection(db, `queue/${id}/queue`)),
        { includeMetadataChanges: false },
        handleQueueUpocming)

    store.commit({
        type: 'setQueueSub',
        value: unsub
    })
    store.commit({
        type: 'setPlayedSub',
        value: unsub1
    })
    store.commit({
        type: 'setUpcomingSub',
        value: unsub2
    })
}

const handleQueueData = async (doc) => {
    const data = doc.data()
    const keys = Object.keys(data)
    if (data.currentSong && !data.currentSong.uri) {
        const currentSongData = data.currentSong
        currentSongData.data = () => { return currentSongData }
        currentSongData.createdAt.toDate = () => { return currentSongData.createdAt }
        const fakeDocs = { docs: [ currentSongData ] }
        const response = await addSpotifyDataAndAnalyticsToSongs(fakeDocs)
        store.state.currentSong = response[0]
    }
    for (const key of keys) {
        store.state.currentQueue[key] = data[key]
    }
}

const handleQueuePlayed = async (querySnapshot) => {
    const songsData = await addSpotifyDataAndAnalyticsToSongs(querySnapshot)
    store.state.currentQueue.played = songsData
}

const handleQueueUpocming = async (querySnapshot) => {
    let songsData = await addSpotifyDataAndAnalyticsToSongs(querySnapshot)
    songsData = songsData.sort((b,a) => a.votes - b.votes)
    store.state.currentQueue.queue = songsData
}

const handleNewPlaylistDocSongs = async (querySnapshot) => {
    const docs = querySnapshot.docs
    store.state.currentPlaylistDoc.songs = docs.map(elem => {
        const data = elem.data()
        return data.spotifyId
    })
}

// playlist wrap
const intervalPlaylistWrap = (doc) => {
    const interval = setTimeout(() => {
        playlistWrap(doc)
    }, 3000)
    store.commit('setPlaylistInterval', interval)
}

const playlistWrap = (doc) => {
    const data = doc.data()
    const dataKeys = Object.keys(data)
    for (const key of dataKeys) {
        store.state.currentPlaylist[key] = data[key]
    }
}

// songs wrap
const intervalSongsWrap = (querySnapshot) => {
    const interval = setTimeout(() => {
        songsWrap(querySnapshot)
    }, 3000)
    store.commit('setSongsInterval', interval)
}

const songsWrap = async (querySnapshot) => {
    const mySongs = querySnapshot
    // format the songs with statistics and such
    const allSpotData = await addSpotifyDataAndAnalyticsToSongs(mySongs)
    // uniqueArtists -> dictionary of [artistName] : count
    await extractArtistsAndAddAnalytics(allSpotData)
    // songs -> songs are the spotify song objects
    store.state.currentPlaylist.songs = allSpotData
}

export const getPlaylists = async (showHidden=false) => {
    try {
        const db = getFirestore();
        let allPlaylists
        if (showHidden) {
            allPlaylists= await getDocs(collection(db, 'playlist'))
        } else {
            allPlaylists= await getDocs(query(collection(db, 'playlist'), where('hidden','==', false)))
        }
        return allPlaylists.docs.map(elem => {
            const data = elem.data()
            data.docId = elem.id
            return data
        })
    } catch (err) {
        console.log(err)
        return []
    }
}

export const formatDate = (date) => {
    const str = date.toString()
    const split = str.split(' ')
    const oth = [split[1], split[2], split[3]].join(' ')
    return oth
}

export const validateNewPlaylistName = async (uid, name) => {
    try {
        const db = getFirestore();
        const allPlaylists = await getDocs(collection(db, 'playlist'), where('userId', '==', uid))
        const docs = allPlaylists.docs.map(doc => doc.data())
        const filtered = docs.filter(elem => elem.name === name)
        return filtered.length === 0
    } catch (err) {
        console.log(err)
        return false
    }
}

const addSpotifyDataAndAnalyticsToSongs = async (mySongs) => {
    const mySongsDocs = mySongs.docs

    // extract data from documents
    const songs = mySongsDocs.map(elem => {
        const d = elem.data()
        d.docId = elem.id
        if (d.createdAt) {
            d.createdAt = d.createdAt.toDate()
            d.createdAtFormatted = formatDate(d.createdAt)
        }
        return d
    }).sort((a,b) => a.createdAt - b.createdAt)

    // get the ids of all songs being added
    const out = {}
    for (const song of songs) {
      out[song.spotifyId] = song
    }
    // extract all missing ids
    const newIdsDict = {}
    for (const key in out) {
      if (!(key in store.state.songsCache)) {
        newIdsDict[key] = 1
      }
    }
    const newIds = Object.keys(newIdsDict)
    // fetch all data from spotify
    let allSpotData = []
    let allAnalysisData = []
    for (let i = 0; i < newIds.length; i += 50) {
      // get spotify song data
      const spotSongData = await spotifyService.getSongs(newIds.slice(i, i + 50).join(','))
      // get spotify analysis data
      const spotStatsData = await spotifyService.getStats(newIds.slice(i, i + 50).join(','))
      // merge them into master lists
      allSpotData = [...allSpotData, ...spotSongData.tracks.filter(elem => elem !== null)]
      allAnalysisData = [...allAnalysisData, ...spotStatsData.audio_features.filter(elem => elem !== null)]
    }
    // update the missing ids in the cache
    for (const song of allSpotData) {
      song.artistString = song.artists.map(elem => elem.name).join(', ')
      song.releaseYear = parseInt(song.album.release_date.substring(0, 4))
      newIdsDict[song.id] = song
    }
    for (const analysis of allAnalysisData) {
        newIdsDict[analysis.id].analysis = analysis
    }
    store.commit('addToSongsCache', newIdsDict)
    // add spotify and analysis data to songs object and return them
    return songs.map(song => {
        const songFromCache = store.state.songsCache[song.spotifyId]
        const songKeys = Object.keys(songFromCache)
        for (const key of songKeys) {
            song[key] = songFromCache[key]
        }
        return song
    })
}

const extractArtistsAndAddAnalytics = async (spotSongs) => {
    const artistsDict = {}
    for (const song of spotSongs) {
        const arts = song.artists
        if (arts && arts.length && arts.length > 0) {
            for (const artist of arts) {
                const { id } = artist
                if (id in artistsDict) {
                    artistsDict[id]++
                } else {
                    artistsDict[id] = 1
                }
            }
        }
    }
    const artists = Object.keys(artistsDict)

    const newIds = {}

    for (const key of artists) {
        if (!(key in store.state.artistsCache)) {
            newIds[key] = 1
        }
    }

    const newIdKeys = Object.keys(newIds)

    let allArtists = []
    for (let i = 0; i < newIdKeys.length; i+=50) {
        const artData = await spotifyService.getArtists(newIdKeys.slice(i, i+50).join(','))
        const extractedArtists = artData.artists
        allArtists = [...allArtists, ...extractedArtists]
    }
    store.commit('addToArtistsCache', allArtists)

    const out = []

    for (const key of artists) {
        const artist = store.state.artistsCache[key]
        artist.artistCount = artistsDict[key]
        out.push(artist)
    }

    return out
}

export const getPlaylist = async (id) => {
    try {
        // if we've already cached the song then get it
        if (store.getters.getCurrentPlaylist && store.getters.getCurrentPlaylist.docId === id) {
            return store.getters.getCurrentPlaylist
        }

        const db = getFirestore();
        // get initial doc
        const myDoc = await getDoc(doc(db, 'playlist', id))
        const data = myDoc.data()
        data.docId = myDoc.id

        // gets songs collection within doc
        const mySongs = await getDocs(collection(db, `playlist/${myDoc.id}/songs`))
        // format the songs with statistics and such
        const allSpotData = await addSpotifyDataAndAnalyticsToSongs(mySongs)
        // songs -> songs are the spotify song objects
        data.songs = allSpotData
        // uniqueArtists -> dictionary of [artistName] : count
        await extractArtistsAndAddAnalytics(allSpotData)

        // update the cache
        store.commit('setCurrentPlaylist', data)
        addPlaylistSub(db, id)
        // return the data
        return data
    } catch (err) {
        console.log(err)
        return undefined
    }
}

export const getPlaylistDoc = async (id) => {
    try {
        if (store.getters.getCurrentPlaylistDoc) {
            return store.getters.getCurrentPlaylistDoc
        } else {
            const db = getFirestore();
            // get initial doc
            const myDoc = await getDoc(doc(db, 'playlist', id))
            const data = myDoc.data()
            data.docId = myDoc.id
            const mySongs = await getDocs(collection(db, `playlist/${myDoc.id}/songs`))
            // return the data
            data.songs = mySongs.docs.map(doc => {
                const data = doc.data()
                return data.spotifyId
            })
            store.commit('setCurrentPlaylistDoc', data)
            addPlaylistDocSub(db, id)
            return data
        }
    } catch (err) {
        console.log(err)
        return undefined
    }
}

export const deletePlaylist = async (id) => {
    try {
        const db = getFirestore();
        // get initial doc
        const myDoc = (doc(db, 'playlist', id))
        await deleteDoc(myDoc)
        return true
    } catch (err) {
        console.log(err)
        return false
    }
}

export const addSongToPlaylist = async (data) => {
    try {
        const db = getFirestore();
        const currentUser = store.getters.getCurrentUser ? store.getters.getCurrentUser : {}
        const { uid, displayName } = currentUser

        const { spotifyId, action, version, createdAt, playlistId } = data

        const userName = data.userName ? data.userName : displayName
        const userId = data.userId ? data.userId : uid

        const myObj = {
            userName,
            userId,
            spotifyId,
            action,
            version,
            createdAt
        }

        await addDoc(collection(db, `playlist/${playlistId}/songs`), myObj)

        return myObj

    } catch (err) {
        console.log(err)
        return undefined
    }
}

export const createPlaylist = async (name) => {
    try {
        const db = getFirestore();
        const currentUser = store.getters.getCurrentUser
        const { uid, displayName } = currentUser

        const myObj = {
            name,
            userId: uid,
            userName: displayName,
            createdAt: new Date(),
            version: 1.0,
            masterVersion: 1.0,
            editors: [],
            tags: [],
            hidden: true,
            metrics: {
                views: 0,
                likes: 0
            }
        }
        const docRef = await addDoc(collection(db, "playlist"), myObj)
        return docRef
    } catch (err) {
        console.log(err)
        return undefined
    }
}

export const addContributorToPlaylist = async (playlistId, user) => {
    try {
        const db = getFirestore();
        const myDoc = doc(db, `playlist/${playlistId}`)
        const docRef = await getDoc(myDoc)
        const docData = docRef.data()
        console.log(docData)

        const { editors } = docData

        editors.push(user)

        await setDoc(myDoc, { editors }, { merge: true })

        return true
    } catch (err) {
        console.log(err)
        return false
    }
}

export const addTagToPlaylist = async (playlistId, tag) => {
    try {
        const db = getFirestore();
        const myDoc = doc(db, `playlist/${playlistId}`)
        const docRef = await getDoc(myDoc)
        const docData = docRef.data()

        const { tags } = docData

        tags.push(tag)

        await setDoc(myDoc, { tags }, { merge: true })

        return true
    } catch (err) {
        console.log(err)
        return false
    }
}

export const incrementPlaylistVersion = async (playlistId) => {
    try {
        const db = getFirestore();
        const myDoc = doc(db, `playlist/${playlistId}`)
        const docRef = await getDoc(myDoc)
        const docData = docRef.data()

        const { version } = docData

        await setDoc(myDoc, { version : version + 0.1 }, { merge: true })

        return true
    } catch (err) {
        console.log(err)
        return false
    }
}

export const incrementPlaylistViews = async (playlistId) => {
    try {
        const db = getFirestore();
        const myDoc = doc(db, `playlist/${playlistId}`)
        const docRef = await getDoc(myDoc)
        const docData = docRef.data()

        const { views, likes } = docData.metrics

        await setDoc(myDoc, { metrics: { likes, views: views + 1 } }, { merge: true })

        return true
    } catch (err) {
        console.log(err)
        return false
    }
}

export const toggleAllowPublicSuggestions = async (playlistId, value) => {
    try {
        const db = getFirestore();
        const myDoc = doc(db, `playlist/${playlistId}`)

        await setDoc(myDoc, { allowPublicSuggestions : value }, { merge: true })

        return true
    } catch (err) {
        console.log(err)
        return false
    }
}

export const togglePlaylistHidden = async (playlistId, value) => {
    try {
        const db = getFirestore();
        const myDoc = doc(db, `playlist/${playlistId}`)

        await setDoc(myDoc, { hidden : value }, { merge: true })

        return true
    } catch (err) {
        console.log(err)
        return false
    }
}

export const updateUser = async () => {
    try {
        const db = getFirestore();
        const currentUser = store.getters.getCurrentUser
        const { uid, displayName, email } = currentUser
        const myDoc = doc(db, `user/${uid}`)
        await setDoc(myDoc, {
            userId: uid,
            userName: displayName,
            email
        })
    } catch (err) {
        console.log(err)
    }
}

export const getUserByEmail = async (email) => {
    try {
        const db = getFirestore();
        const q = query(collection(db, 'user'), where('email', '==', email))
        const querySnapshot = await getDocs(q)
        const docs = querySnapshot.docs
        if (docs) {
            const d = docs[0]
            if (d && d.exists) {
                const data = d.data()
                data.docId = d.id
                return data
            }
        }
        return undefined
    } catch (err) {
        console.log(err)
        return undefined
    }
}

export const getUserById = async (userId) => {
    try {
        const db = getFirestore();
        const q = query(collection(db, 'user'), where('userId', '==', userId))
        const querySnapshot = await getDocs(q)
        const docs = querySnapshot.docs
        if (docs) {
            const d = docs[0]
            if (d && d.exists) {
                const data = d.data()
                data.docId = d.id
                return data
            }
        }
        return undefined
    } catch (err) {
        console.log(err)
        return undefined
    } 
}

export const getPlaylistsForUser = async (userId) => {
    try {
        const db = getFirestore();
        const allPlaylists = await getDocs(query(collection(db, 'playlist'), where('userId', '==', userId)))
        return allPlaylists.docs.map(elem => {
            const data = elem.data()
            data.docId = elem.id
            return data
        })
    } catch (err) {
        console.log(err)
        return []
    }
}

export const createQueue = async () => {
    try {
        const db = getFirestore();
        const currentUser = store.getters.getCurrentUser
        const { uid, displayName } = currentUser

        const myObj = {
            userName: displayName,
            userId: uid,
            createdAt: new Date(),
            currentSong: null
        }
        const docRef = await addDoc(collection(db, "queue"), myObj)
        return docRef
    } catch (err) {
        console.log(err)
        return undefined
    }
}

export const createQueueFromPlaylist = async (playlistId) => {
    try {
        const playlist = await getPlaylist(playlistId)
        const { songs } = playlist

        const firstSong = songs[0]
        const restOfSongs = songs.slice(1)

        const db = getFirestore();
        const currentUser = store.getters.getCurrentUser
        const { uid, displayName } = currentUser
        const myObj = {
            userName: displayName,
            userId: uid,
            createdAt: new Date(),
            currentSong: firstSong
        }
        const docRef = await addDoc(collection(db, "queue"), myObj)
        const docId = docRef.id

        for (const song of restOfSongs) {
            const obj = {
                ...song,
                createdAt: new Date(),
                userName: 'Guest',
                userId: 'GuestId',
                votes: 1,
            }
            await addDoc(collection(db, `queue/${docId}/queue`), obj)
        }

        return docRef
    } catch (err) {
        console.log(err)
        return false
    }
};

export const getQueue = async (id) => {
    try {
        const db = getFirestore();

        const myQ = store.getters.getCurrentQueue
        if ( myQ && myQ.docId === id) {
            return myQ
        }

        // get queue document
        const docRef = await getDoc(doc(db, `queue/${id}`))
        const docData = docRef.data()
        docData.docId = docRef.id

        if (docData.currentSong) {
            const obj = docData.currentSong
            obj.data = () => { return obj }
            obj.createdAt.toDate = () => { return obj.createdAt }
            const fakeDocs = { docs: [ obj ] }
            const response = await addSpotifyDataAndAnalyticsToSongs(fakeDocs)
            docData.currentSong = response[0]
        }

        // get played subCollection and sort by timeFinished ascending
        const playedCollectionSnapshot = await getDocs(query(collection(db, `queue/${id}/played`), orderBy('timeFinished', 'asc')))
        // get queue subCollection and sort by votes descending
        const queueCollectionSnapshot = await getDocs(query(collection(db, `queue/${id}/queue`), orderBy('votes', 'desc')))

        docData.played = await addSpotifyDataAndAnalyticsToSongs(playedCollectionSnapshot)
        docData.queue = await addSpotifyDataAndAnalyticsToSongs(queueCollectionSnapshot)

        docData.queue = docData.queue.sort((b,a) => a.votes - b.votes)
        store.state.currentQueue = docData
        addQueueSub(db, id)
        return docData
    } catch (err) {
        console.log(err)
        return undefined
    }
}

// if the song is in the queue then upvote it otherwise add it
export const addSongToQueue = async (id, songObj) => {
    try {
        const db = getFirestore();
    
        const queueObject = await getQueue(id)

        if (!queueObject.currentSong) {
            await setDoc(doc(db, `queue/${id}`), { currentSong: songObj }, { merge: true })
        } else {
    
        const index = queueObject.queue.findIndex(song => song.spotifyId === songObj.spotifyId)
    
        // add it to queue
        if (index === -1) {
            await addDoc(collection(db, `queue/${id}/queue`), songObj)
        } else {
            const songRef = queueObject.queue[index]
            await setDoc(doc(db, `queue/${id}/queue/${songRef.docId}`), { votes: songRef.votes + 1 }, { merge: true })
        }
    }

        return true
    } catch (err) {
        console.log(err)
        return false
    }
}

// upvote the song in the queue
export const upvoteSongInQueue = async (id, songObj) => {
    try {
        const db = getFirestore();
    
        const queueObject = await getQueue(id)
    
        const index = queueObject.queue.findIndex(song => song.spotifyId === songObj.spotifyId)
    
        // add it to queue
        if (index === -1) {
            return true
        } else {
            const songRef = queueObject.queue[index]
            await setDoc(doc(db, `queue/${id}/queue/${songRef.docId}`), { votes: songRef.votes + 1 }, { merge: true })
        }

        return true
    } catch (err) {
        console.log(err)
        return false
    }
}

// downvote the song in the queue
export const downvoteSongInQueue = async (id, songObj) => {
    try {
        const db = getFirestore();
        const queueObject = await getQueue(id)
    
        const index = queueObject.queue.findIndex(song => song.spotifyId === songObj.spotifyId)
    
        // add it to queue
        if (index === -1) {
            return true
        } else {
            const songRef = queueObject.queue[index]
            await setDoc(doc(db, `queue/${id}/queue/${songRef.docId}`), { votes: songRef.votes - 1 }, { merge: true })
        }

        return true
    } catch (err) {
        console.log(err)
        return false
    }
}

// take the current song if there is one and add it into played, get the top song in queue and add it as currentSong, make sure that we remove that song from the queue
export const queueAndReturnNextSong = async (id) => {
    try {
        const db = getFirestore();
        const queueObject = await getQueue(id)

        if (queueObject.currentSong) {
            const data = queueObject.currentSong
            data.timeFinished = new Date()
            const newData = {
                spotifyId: data.spotifyId,
                createdAt: data.createdAt,
                userName: data.userName,
                userId: data.userId,
                votes: data.votes,
                timeFinished: data.timeFinished
            }
            await addDoc(collection(db, `queue/${id}/played`), newData)
        }
        if (queueObject.queue.length > 0) {
            const data = queueObject.queue[0]
            // update current song
            await setDoc(doc(db, `queue/${id}`), { currentSong: data }, { merge: true })
            // delete doc in queue
            await deleteDoc(doc(db, `queue/${id}/queue/${data.docId}`))
            return data
        } else {
            await setDoc(doc(db, `queue/${id}`), { currentSong: null }, { merge: true })
        }
        return false
    } catch (err) {
        console.log(err)
        return false
    }
}

export const returnNextSongInQueue = async (id) => {
    try {
        const queueObject = await getQueue(id)

        if (queueObject.queue.length > 0) {
            const data = queueObject.queue[0]
            return data
        }
        return false
    } catch (err) {
        console.log(err)
        return false
    }
}

export const createPlayer = async (playlistId, songs) => {
    try {
        const db = getFirestore();
        await setDoc(doc(db, `player/${playlistId}`), { songs })
        return true
    } catch (err) {
        console.log(err)
        return false
    }
}

export const getYoutubeData = async (spotifyId) => {
    try {
        const db = getFirestore();
        const docRef = await getDoc(doc(db, `youtubeIds/${spotifyId}`))
        const data = docRef.data()
        return data
    } catch (err) {
        console.log(err)
        return undefined
    }
}

export const updateYoutubeStartTime = async (spotifyId, startTime) => {
    try {
        const db = getFirestore();
        await setDoc(doc(db, `youtubeIds/${spotifyId}`), { startTime }, {merge : true})
        return true
    } catch (err) {
        console.log(err)
        return false
    }
}

export const flagYoutubeStartTime = async (spotifyId) => {
    try {
        const db = getFirestore();
        await setDoc(doc(db, `youtubeIds/${spotifyId}`), { incorrectStartTime: true }, {merge : true})
        return true
    } catch (err) {
        console.log(err)
        return false
    }
}

export const getPlayer = async (playlistId) => {
    try {
        const db = getFirestore();
        const docRef = await getDoc(doc(db, `player/${playlistId}`))
        const data = docRef.data()
        data.docId = docRef.id
        return data
    } catch (err) {
        console.log(err)
        return undefined
    }
}

export const getSongsWithKeyWords = async (keyWords) => {
    try {
        const db = getFirestore();
        const col = collection(db, `youtubeIds`)
        const myQuery = query(col, where('summaryWords', 'array-contains-any', keyWords))
        const querySnapshot = await getDocs(myQuery)
        const allSpotData = await addSpotifyDataAndAnalyticsToSongs(querySnapshot)
        return allSpotData
    } catch (err) {
        console.log(err)
        return []
    }
}

export const fetchSpotifyToken = async () => {
    try {
        const db = getFirestore();
        const tokenDocRef = doc(db, `spotify/token`);

        // Fetch the token immediately
        const initialDoc = await getDoc(tokenDocRef);
        const initialData = initialDoc.data();
        const initialValue = initialData.value;
        if (initialValue) {
            store.commit('setSpotifyToken', initialValue);
        }

        // Set up a listener for changes to the Spotify token
        onSnapshot(tokenDocRef, (doc) => {
            const data = doc.data();
            const newValue = data.value;
            if (newValue) {
                store.commit('setSpotifyToken', newValue);
            }
        });

    } catch (err) {
        console.log(err);
    }
};