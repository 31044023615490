<template lang="pug">
v-app
    v-main(:key='mainKey')
        NavBar(v-if='navbarVisible')
        router-view.router
</template>

<script>
import NavBar from './components/NavBar.vue'
import router from './router'

export default {
  name: 'App',  
  components: {
    NavBar  
  },
  data: () => ({
    mainKey: 0,
    navbarVisible: true
  }),
  watch:{
    $route (){
        this.navbarVisible = router.currentRoute.name !== 'Player'
      }
  },
  async mounted(){
    await this.$dataGrabber.fetchSpotifyToken()
    this.navbarVisible = router.currentRoute.name !== 'Player'
  }
}
</script>

<style scoped lang='scss'>
  .router {
    width: 100%;
    display: flex;
    flex: 1;
    padding: 0px;
  }
</style>