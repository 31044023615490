import axios from 'axios'

import store from '../store/index'

export const getToken = async () => {
    const t = store.getters.getSpotifyToken
    if (t) {
        return t
    }
    return null;
}

const shuffle = (array) => {
    let currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
    return array;
    }

export const getSongs = async (val) => {
    try {
        const token = await getToken()
        const response = await axios.get(
            `https://api.spotify.com/v1/tracks?ids=${val}`,
            {
                headers: { 'Authorization': 'Bearer ' + token }
            }
        )
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const getArtists = async (val) => {
    try {
        const token = await getToken()
        const response = await axios.get(
            `https://api.spotify.com/v1/artists?ids=${val}`,
            {
                headers: { 'Authorization': 'Bearer ' + token }
            }
        )
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const getArtistTopTracks = async (val) => {
    try {
        const token = await getToken()
        const response = await axios.get(
            `https://api.spotify.com/v1/artists/${val}/top-tracks?market=US`,
            {
                headers: { 'Authorization': 'Bearer ' + token }
            }
        )
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const getArtist = async (val) => {
    try {
        const token = await getToken()
        const response = await axios.get(
            `https://api.spotify.com/v1/artists/${val}`,
            {
                headers: { 'Authorization': 'Bearer ' + token }
            }
        )
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const getStats = async (val) => {
    try {
        const token = await getToken()
        const response = await axios.get(
            `https://api.spotify.com/v1/audio-features?ids=${val}`,
            {
                headers: { 'Authorization': 'Bearer ' + token }
            }
        )
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const search = async(val, num) => {
    try {
      const token = await getToken()
      const response = await axios.get(
        'https://api.spotify.com/v1/search?q='+(val)+'&type=track,artist&limit='+num,
        {
          headers: {'Authorization': 'Bearer ' + token}
        }
      );
      return response.data;
    } catch (error) {
      console.log(error)
      return undefined
    }
}

export const addAnalyticsToSong = async (song) => {
    if (song) {
        const { id } = song
        const stats = await getStats(id)
        if (stats) {
            const { audio_features } = stats
            const analysis = audio_features[0]
            song.analysis = analysis
            return song
        }
    }
    return song
}

export const getUniqueArtists = (songs) => {
    const out = {}
    
    for (const song of songs) {
        const artists = song.artists
        for (const artist of artists) {
            const { name } = artist
            if (name in out) {
                out[name]++
            } else {
                out[name] = 1
            }
        }
    }

    return out
}

export const getPlaylists = async (token) => {
    try {
        let num = 0
        let allItems = []
        let url = 'https://api.spotify.com/v1/me/playlists'
        // eslint-disable-next-line no-constant-condition
        while (true) {
            const response = await axios.get(
                url,
                {
                    headers: { 'Authorization': 'Bearer ' + token }
                }
            )
            const { items, total, next } = response.data
            num += items.length
            allItems = [...allItems, ...items]
            if (num < total) {
                url = next
            } else {
                return allItems
            }
        }
    } catch (error) {
        return undefined
    }
}

export const getPlaylist = async (token, urlIn) => {
    try {
        let num = 0
        let allItems = []
        let url = urlIn
        // eslint-disable-next-line no-constant-condition
        while (true) {
            const response = await axios.get(
                url,
                {
                    headers: { 'Authorization': 'Bearer ' + token }
                }
            )
            const { items, total, next } = response.data
            num += items.length
            allItems = [...allItems, ...items]
            if (num < total) {
                url = next
            } else {
                return allItems
            }
        }
    } catch (error) {
        console.log(error)
    }
}

export const getPlaylistFromId = async (playlistId) => {
    try {
        const token = await getToken()
        const response = await axios.get(
            `https://api.spotify.com/v1/playlists/${playlistId}`,
            {
                headers: { 'Authorization': 'Bearer ' + token }
            }
        )
        return response.data.tracks
    } catch (error) {
        console.log(error)
    }
}

export const createPlaylist = async (token, userId, playlistName, songs) => {
    const headers = {
        headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      }

    const response = await axios.post(
        `https://api.spotify.com/v1/users/${userId}/playlists`,
        {
            "name": playlistName,
            "description": "Playlist Hound",
            "public": false
        },
        headers
      )
      for (let i = 0; i < songs.length; i+=100) {
        await axios.post(
            `https://api.spotify.com/v1/playlists/${response.data.id}/tracks?uris=${songs.slice(i, i+100 < songs.length ? i + 100 : songs.length).map(elem => elem.uri).join(',')}`,
            undefined,
            headers
        )
      }
}

export const getUser = async (token) => {
    const response = await axios.get(
        `https://api.spotify.com/v1/me`,
        {
            headers: { 'Authorization': 'Bearer ' + token }
        }
    )
    return response.data
}

export const getRecommendations = async (props, excludeIds=[]) => {
    let { artists, genres, tracks, acousticness, danceability, energy, instrumentalness, liveness, popularity, speechiness, valence, target } = props
    let out = []
    const limit = target < 100 ? target : 100
    try {
        while (out.length < target) {

            if (!artists) {
                artists = []
            } if (!genres) {
                genres = []
            } if (!tracks) {
                tracks = []
            } else {
                shuffle(tracks)
                tracks = tracks.slice(0, tracks.length < 5 ? tracks.length : 5)
            }
            let url = `https://api.spotify.com/v1/recommendations?seed_artists=${artists.join(',')}&seed_genres=${genres.join(',')}&seed_tracks=${tracks.join(',')}&limit=${limit}`
        
            if (acousticness) {
                url += `&target_acousticness=${acousticness}`
            }
            if (danceability) {
                url += `&target_danceability=${danceability}`
            }
            if (energy) {
                url += `&target_energy=${energy}`
            }
            if (instrumentalness) {
                url += `&target_instrumentalness=${instrumentalness}`
            }
            if (liveness) {
                url += `&target_liveness=${liveness}`
            }
            if (popularity) {
                url += `&target_popularity=${popularity}`
            }
            if (speechiness) {
                url += `&target_speechiness=${speechiness}`
            }
            if (valence) {
                url += `&target_valence=${valence}`
            }
        
            const token = await getToken()

              const response = await axios.get(
                  url,
                {
                  headers: {'Authorization': 'Bearer ' + token}
                }
              );
              const outTracks = response.data.tracks
              for (const track of outTracks) {
                  const indexInExclude = excludeIds.findIndex(elem => elem === track.id)
                  if (indexInExclude === -1) {
                      out.push(track)
                  }
              }
        }
        return out
    } catch (error) {
      console.log(error);
    }
  }

  export const getAllSongsForArtist = async (artistId, birthYear=null) => {
    try {
        let albums = []
        let allSongs = {}
        let smallYear = 10000
        let bigYear = 0
        const token = await getToken()
        let url = `https://api.spotify.com/v1/artists/${artistId}/albums?include_groups=album,single`
        // get all albums
        // eslint-disable-next-line no-constant-condition
        while (true) {
            const response = await axios.get(
                url,
                {
                    headers: { 'Authorization': 'Bearer ' + token }
                }
            )
            const { items, total, next } = response.data
            console.log(items)
            url = next
            albums = [...albums, ...items]

            if (albums.length === total) {
                break
            }
            if (!url) {
                break
            }
        }
        const allAlbums = []
        // get all songs
        for (let i=0; i < albums.length; i+=20) {
            const albumsSlice = albums.slice(i, i+20)
            const ids = albumsSlice.map(album => album.id)
            const response = await axios.get(
                `https://api.spotify.com/v1/albums?ids=${ids.join(',')}`,
                {
                    headers: { 'Authorization': 'Bearer ' + token }
                }
            )
            const { data } = response
            const albumsResponse = data.albums
            for (const album of albumsResponse) {
                allAlbums.push(album)
                const { release_date } = album
                const year = parseInt(release_date.substring(0, 4))
                if (year > bigYear) {
                    bigYear = year
                } if (year < smallYear) {
                    smallYear = year
                }
                const items = album.tracks.items
                for (const item of items) {
                    item.year = year
                    allSongs[item.id] = item
                }
            }
        }

        // for songs ------------------------------------------

        // put song into bins by year
        const allSongsList = []
        for (const key in allSongs) {
            const song = allSongs[key]
            allSongsList.push(song)
        }
        allSongsList.sort((a,b) => a.year - b.year)
        // refactor years into [Old, Semi-Old, Semi-New, New]
        const allSongsOut = {}
        let fifteen = Math.floor(allSongsList.length * 0.15)
        let thirtyFive = Math.floor(allSongsList.length * 0.35)

        allSongsOut['All'] = allSongsList
        allSongsOut['Old'] = allSongsList.slice(0, fifteen)
        allSongsOut['Semi-old'] = allSongsList.slice(fifteen, fifteen + thirtyFive)
        allSongsOut['Semi-new'] = allSongsList.slice(fifteen + thirtyFive, fifteen + thirtyFive + thirtyFive)
        allSongsOut['New'] = allSongsList.slice(fifteen + thirtyFive + thirtyFive, allSongsList.length)
        if (birthYear) {
            // 18-23
            const collegeStart = birthYear + 18
            const collegeEnd = birthYear + 23
            allSongsOut['College'] = allSongsList.filter(song => song.year && song.year >= collegeStart && song.year <= collegeEnd)
            // 14-18
            const schoolStart = birthYear + 14
            const schoolEnd = birthYear + 18
            allSongsOut['High-school'] = allSongsList.filter(song => song.year && song.year >= schoolStart && song.year <= schoolEnd)
        } else {
            allSongsOut['College'] = []
            allSongsOut['High-school'] = []
        }

        // for albums ----------------------------------------------
        const allAlbumsMapped = allAlbums.map(album => {
            album.year = parseInt(album.release_date.split('-')[0])
            return album
        })
        allAlbumsMapped.sort((a,b) => a.year - b.year)
        // refactor years into [Old, Semi-Old, Semi-New, New]
        const allAlbumsOut = {}
        fifteen = Math.floor(allAlbumsMapped.length * 0.15)
        thirtyFive = Math.floor(allAlbumsMapped.length * 0.35)

        allAlbumsOut['All'] = allAlbumsMapped
        allAlbumsOut['Old'] = allAlbumsMapped.slice(0, fifteen)
        allAlbumsOut['Semi-old'] = allAlbumsMapped.slice(fifteen, fifteen + thirtyFive)
        allAlbumsOut['Semi-new'] = allAlbumsMapped.slice(fifteen + thirtyFive, fifteen + thirtyFive + thirtyFive)
        allAlbumsOut['New'] = allAlbumsMapped.slice(fifteen + thirtyFive + thirtyFive, allAlbumsMapped.length)
        if (birthYear) {
            // 18-23
            const collegeStart = birthYear + 18
            const collegeEnd = birthYear + 23
            allAlbumsOut['College'] = allAlbumsMapped.filter(song => song.year && song.year >= collegeStart && song.year <= collegeEnd)
            // 14-18
            const schoolStart = birthYear + 14
            const schoolEnd = birthYear + 18
            allAlbumsOut['High-school'] = allAlbumsMapped.filter(song => song.year && song.year >= schoolStart && song.year <= schoolEnd)
        } else {
            allAlbumsOut['College'] = []
            allAlbumsOut['High-school'] = []
        }
    
        // return it all
        return {
            allSongs: allSongsOut,
            allAlbums: allAlbumsOut
        }
    } catch (error) {
        console.log(error)
        return []
    } 
  }

  export const getSimilarArtists = async (artistId) => {
    try {
        const token = await getToken()
        const response = await axios.get(
            `https://api.spotify.com/v1/artists/${artistId}/related-artists`,
            {
                headers: { 'Authorization': 'Bearer ' + token }
            }
        )
        return response.data
    } catch (error) {
        console.log(error)
    }
  }

  export const getAvailableDevices = async (token) => {
    try {
        const response = await axios.get(
            `https://api.spotify.com/v1/me/player/devices`,
            {
                headers: { 'Authorization': 'Bearer ' + token }
            }
        )
        return response.data
    } catch (error) {
        console.log(error)
    }
  }

  export const getCurrentlyPlaying = async (token) => {
    try {
        const response = await axios.get(
            `https://api.spotify.com/v1/me/player/currently-playing`,
            {
                headers: { 'Authorization': 'Bearer ' + token }
            }
        )
        return response.data
    } catch (error) {
        console.log(error)
    }
  }

  export const playSong = async (token, deviceId, songId) => {
    const headers = {
        headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      }

        await axios.put(
        `https://api.spotify.com/v1/me/player/play?device_id=${deviceId}`,
        {
            "uris": [`spotify:track:${songId}`],
        },
        headers
      )
}

export const pauseSong = async (token, deviceId) => {
    const headers = {
        headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      }

        await axios.put(
        `https://api.spotify.com/v1/me/player/pause?device_id=${deviceId}`,
        {
        },
        headers
      )
}

export const getPreamble = async (songName, artistName, pastResponses, context) => {
    try {

        let pastResponsesString = ''
        // get the last 5 responsesn and join them with new lines
        for (let i = 0; i < pastResponses.length; i++) {
            const response = pastResponses[i]
            pastResponsesString += response + '\n'
        }

        const data = JSON.stringify({
            "inputs": { "song_name": songName, "artist_name": artistName, "spatial_context": context, "conversation_history": pastResponsesString },
            "pipeline_id": "66ab9cafe363e88880d0b03a",
            "username": "poshea"
          });
          
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://api.vectorshift.ai/api/pipelines/run',
            headers: { 
              'Api-Key': 'sk_9sAkOaKLmDQEYh2JDrYJmZupxbr1764ztGMIkw7eh2BA4Sbu', 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          const response = await axios.request(config)
          const responseData = response.data
          const output1 = responseData['output_1']
          const output2 = responseData['output_2']
          const audioString = output1['audio']
          const textString = output2['text']

          return {
            'audio': `data:audio/wav;base64,${audioString}`,
            'text': textString
          }

    } catch (err) {
        console.log(err)
        return false
    }
};